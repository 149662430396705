import 'react-hot-loader/patch';
import 'reflect-metadata';
import '@shared/i18n';

import 'reset-css';
import 'font-awesome/css/font-awesome.min.css';

import DateFnsUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import { CombinedOptionsProvider } from '@agency/contexts/OptionsContext';
import { createTheme } from '@agency/theme/utils/create-theme';
import { AppId, ConfigProvider } from '@shared/contexts/ConfigContext';
import { FieldsProvider } from '@shared/contexts/FieldsContext';
import { PusherProvider } from '@shared/contexts/PusherContext';
import { UnsavedChangesProvider } from '@shared/contexts/UnsavedChangesContext';
import store from '@shared/redux/store';
import { history } from '@shared/utils/history';
import { queryClient } from '@shared/utils/http-client';

import { App } from './App';

import '@shared/assets/scss/iconFonts.scss';
import '@shared/assets/scss/mapOverride.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createTheme();

render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider appId={AppId.Agency}>
            <PusherProvider>
              <UnsavedChangesProvider>
                <CombinedOptionsProvider>
                  <FieldsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <App />
                    </MuiPickersUtilsProvider>
                  </FieldsProvider>
                </CombinedOptionsProvider>
              </UnsavedChangesProvider>
            </PusherProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
